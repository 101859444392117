import { PencilIcon } from '@heroicons/react/solid';
import { apiEnums } from '@nodal/api';
import { t } from '@nodal/i18n';
import { ButtonLoading } from '@nodal/uikit/components/ButtonLoading';
import { FavoriteIcon } from '@nodal/uikit/components/FavoriteIcon';
import compact from 'lodash/compact';
import { forwardRef, useImperativeHandle, useRef } from 'react';

import { IntroductionRequestCard } from '@core/flows/IntroductionRequestCard';

import { Badges } from './Bagdes';
import { PhotoContainer } from './PhotoContainer';
import { ProfileCard } from './ProfileCard';
import { GetToKnowSectionDetails } from './SectionDetails/GetToKnowSectionDetails';
import { InsuranceReviewSectionDetails } from './SectionDetails/InsuranceReviewSectionDetails';
import { JourneySectionDetails } from './SectionDetails/JourneySectionDetails';
import { ParentsPersonalSectionDetails } from './SectionDetails/ParentsPersonalSectionDetails';
import { PreferencesSectionDetails } from './SectionDetails/PreferencesSectionDetails';
import { ScreeningSectionDetails } from './SectionDetails/ScreeningSectionDetails';
import { SurrogatePersonalSectionDetails } from './SectionDetails/SurrogatePersonalSectionDetails';
import { VideoSectionDetails } from './SectionDetails/VideoSectionDetails';
import { WordsFromNodalSectionDetails } from './SectionDetails/WordsFromNodalSectionDetails';
import { getFormattedName } from './utils';

import type { MatchProfileProps, PdfRefs } from './MatchProfile.interface';

export const MatchProfile = forwardRef<PdfRefs, MatchProfileProps>(
  (
    {
      profileData,
      introduction,
      owner,
      userMe,
      onRequestIntroduction,
      onUpdateIntroduction,
      requestIntroductionHidden,
      onClickFavorite,
      isFavoriteProfile,
      onEdit,
      screeningDetails,
    },
    ref,
  ) => {
    const { profile, role, show_matching_badge } = profileData || {};
    const { video_url } = profile || {};
    const topSectionRef = useRef<HTMLDivElement>(null);
    const screeningDetailsRef = useRef<HTMLDivElement>(null);
    const getToKnowUsRef = useRef<HTMLDivElement>(null);
    const wordsFromNodalRef = useRef<HTMLDivElement>(null);
    const insuranceRef = useRef<HTMLDivElement>(null);
    const preferencesRef = useRef<HTMLDivElement>(null);
    const journeyRef = useRef<HTMLDivElement>(null);
    const requiredFields = ['lgbtq_identify', 'is_military'];
    let requiredFieldsCounter = 0;

    if (role === 'par') {
      for (const [key, value] of Object.entries(profile)) {
        if (requiredFields.some((e) => e === key)) {
          if (value === null) {
            requiredFieldsCounter++;
          }
        }
      }
    }

    useImperativeHandle(ref, () => ({
      getPdfElements() {
        return topSectionRef?.current &&
          wordsFromNodalRef?.current &&
          getToKnowUsRef?.current &&
          preferencesRef?.current &&
          journeyRef?.current
          ? compact([
              { ref: topSectionRef.current, page: 1 },
              // NOTE: This section not always exist, is optional
              screeningDetailsRef?.current
                ? { ref: screeningDetailsRef.current, page: 1 }
                : undefined,
              { ref: wordsFromNodalRef.current, page: 2 },
              { ref: getToKnowUsRef.current, page: 2 },
              { ref: preferencesRef.current, page: 3 },
              { ref: journeyRef.current, page: 3 },
            ])
          : undefined;
      },
    }));

    const name = getFormattedName(profile);

    const canEdit = owner && userMe?.status !== apiEnums.UserStatusEnum.Dis;

    const isRequestIntroductionVisible =
      !owner &&
      role == apiEnums.UserRoleEnum.Par &&
      !introduction &&
      !requestIntroductionHidden &&
      onRequestIntroduction;

    const isFavoriteButtonVisible =
      !owner && role == apiEnums.UserRoleEnum.Par && onClickFavorite;

    return (
      <div className="flex flex-col gap-4 justify-start items-stretch py-6 px-4 mx-auto w-full max-w-5xl sm:py-8">
        {!!introduction &&
          introduction.stage !== apiEnums.IntroductionStageEnum.Expired &&
          introduction.stage !== apiEnums.IntroductionStageEnum.Broken &&
          userMe?.role &&
          onUpdateIntroduction && (
            <IntroductionRequestCard
              introduction={{ ...introduction, stage: introduction.stage }}
              role={userMe.role}
              onUpdate={onUpdateIntroduction}
              userHidden
            />
          )}
        <div
          className="flex flex-col col-span-12 col-start-2 gap-6 justify-start p-4 w-full bg-white rounded-lg sm:flex-row sm:p-8"
          ref={topSectionRef}
        >
          <PhotoContainer
            photos={profileData?.profile_photos}
            edit={canEdit ? () => onEdit(t('Photos'), 'photos') : undefined}
            showJustJoinedBadge={
              userMe?.show_matching_badge || show_matching_badge
            }
            userId={profileData.id}
          />
          <div className="flex flex-col space-y-10 w-full sm:w-3/5">
            <div className="flex justify-between w-full">
              <h3 className=" text-2xl font-medium leading-8 text-grey-forest-900">
                {name}
              </h3>
              <div className="flex">
                {requiredFieldsCounter > 0 && (
                  <div className="flex items-center py-1 px-3 text-sm text-white bg-rose-500 rounded-lg">
                    {requiredFieldsCounter} incomplete
                  </div>
                )}
                {canEdit && (
                  <button
                    onClick={() => onEdit(t('Bio'), 'personalInformation')}
                    type="button"
                    className="flex gap-2 justify-between items-center px-3"
                  >
                    <PencilIcon className="w-5 h-5 text-forest-400 sm:w-4 sm:h-4" />
                    <p className="hidden text-sm font-medium leading-4 text-forest-500 sm:block">
                      {t('Edit')}
                    </p>
                  </button>
                )}
              </div>

              {isFavoriteButtonVisible && (
                <ButtonLoading
                  variant="primary"
                  className="justify-center px-2"
                  onClick={onClickFavorite}
                >
                  <FavoriteIcon filled={!!isFavoriteProfile} />
                </ButtonLoading>
              )}
            </div>
            {isRequestIntroductionVisible && (
              <ButtonLoading
                variant="primary"
                className="justify-center w-full"
                onClick={onRequestIntroduction}
                disabled={!userMe?.introductions_left}
              >
                {t('Request an Introduction')}
              </ButtonLoading>
            )}
            {(role === apiEnums.UserRoleEnum.Par ||
              role === apiEnums.UserRoleEnum.Nap) && (
              <ParentsPersonalSectionDetails profile={profile} />
            )}
            {role === apiEnums.UserRoleEnum.Dnr && (
              <SurrogatePersonalSectionDetails profile={profile} />
            )}
            <Badges profile={profile} role={role} size="medium" />
          </div>
        </div>
        {role === apiEnums.UserRoleEnum.Dnr && screeningDetails && (
          <ScreeningSectionDetails
            screeningDetails={screeningDetails}
            ref={screeningDetailsRef}
          />
        )}
        {(canEdit || (!canEdit && video_url)) && (
          <ProfileCard
            title={t('Meet {name}', name)}
            edit={canEdit ? (title) => onEdit(title, 'video') : undefined}
          >
            <VideoSectionDetails videoUrl={video_url} />
          </ProfileCard>
        )}
        {role == apiEnums.UserRoleEnum.Dnr && (
          <>
            <ProfileCard
              title={'Words from Nodal'}
              edit={
                !userMe ? (title) => onEdit(title, 'wordsFromNodal') : undefined
              }
              ref={wordsFromNodalRef}
            >
              <WordsFromNodalSectionDetails profile={profile} role={role} />
            </ProfileCard>
            <ProfileCard
              title={'Insurance Review'}
              edit={
                canEdit
                  ? (title) => onEdit(title, 'insuranceReview')
                  : undefined
              }
              ref={insuranceRef}
            >
              <InsuranceReviewSectionDetails profile={profile} role={role} />
            </ProfileCard>
          </>
        )}
        <ProfileCard
          title={
            role === apiEnums.UserRoleEnum.Dnr
              ? t('Get to know me')
              : t('Get to know me/us')
          }
          edit={
            canEdit
              ? (title) =>
                  onEdit(
                    title,
                    'getToKnow',
                    role === apiEnums.UserRoleEnum.Dnr
                      ? t(
                          'For this section, please enter bullet points and our team will help craft the final version that gets presented on your profile.',
                        )
                      : undefined,
                  )
              : undefined
          }
          ref={getToKnowUsRef}
        >
          <GetToKnowSectionDetails profile={profile} role={role} />
        </ProfileCard>
        <ProfileCard
          title={
            role === apiEnums.UserRoleEnum.Dnr
              ? t('Preferences for a Parent(s)')
              : t('Preferences for a Surrogate')
          }
          edit={canEdit ? (title) => onEdit(title, 'preferences') : undefined}
          ref={preferencesRef}
        >
          <PreferencesSectionDetails
            edit={canEdit}
            profile={profile}
            role={role}
          />
        </ProfileCard>
        <ProfileCard
          title={t('Surrogacy Journey Profile')}
          edit={canEdit ? (title) => onEdit(title, 'journey') : undefined}
          ref={journeyRef}
        >
          <JourneySectionDetails profile={profile} role={role} />
        </ProfileCard>
        {isRequestIntroductionVisible && (
          <ProfileCard title={name} className="block sm:flex sm:relative">
            <ButtonLoading
              variant="primary"
              className="relative top-0 right-0 justify-center sm:absolute sm:top-8 sm:right-8"
              onClick={onRequestIntroduction}
              disabled={!userMe?.introductions_left}
            >
              {t('Request an Introduction')}
            </ButtonLoading>
          </ProfileCard>
        )}
      </div>
    );
  },
);

MatchProfile.displayName = 'MatchProfile';
